import et_logo from '../../content/thumbnails/etfavicon.ico'

export default [
  {
    id: 'economic_times',
    title:
      'ETPrime : Security researchers find Covid-19 patient data online; alert government to plug leaks',
    path:
      'https://economictimes.indiatimes.com/tech/internet/security-researchers-find-covid-19-patient-data-online-alert-government-to-plug-leaks/articleshow/78037261.cms',
    description:
      'My finding about COVID patient details leak published in Economic Times',
    staticThumbnail: et_logo,
  },
]
