import secarmy from '../../content/thumbnails/secarmy.jpg'
import null_png from '../../content/thumbnails/null.png'
import owasp from '../../content/thumbnails/owasp.png'
import arpcon from '../../content/thumbnails/arpcon.png'

export default [
  {
    id: 'arpcon_2020',
    description: 'ARPCon, 2020',
    title: 'Websockets As A Bug Bounty Hunter',
    path: 'https://youtu.be/3VU21Xh691M?t=7463',
    staticThumbnail: arpcon,
  },
  {
    id: 'owasp_bangalore',
    description: 'OWASP Bangalore, 2020',
    title: 'Owning Your PC through Innocuous USB',
    path: 'https://www.youtube.com/watch?v=FKBRB3O53mI',
    staticThumbnail: owasp,
  },
  {
    id: 'secarmy',
    description: 'SecArmy, 2019',
    title: 'Bad USB attacks',
    path: 'https://www.youtube.com/watch?v=98DxwvyajV8',
    staticThumbnail: secarmy,
  },
  {
    id: 'null_delhi',
    description: 'Null Delhi, 2019',
    title: 'Owning Your PC through Innocuous USB',
    path:
      'https://null.community/event_sessions/2638-owning-your-pc-from-an-innocuous-usb',
    staticThumbnail: null_png,
  },
]
