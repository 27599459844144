import flutter_pass_notes from '../../content/thumbnails/flutter_pass_notes.png'
import crashy_ball from '../../content/thumbnails/crashy_ball.png'
import python_rat from '../../content/thumbnails/python_rat.png'
import django_social_network from '../../content/thumbnails/django_social_network.png'
import omnisec from '../../content/thumbnails/omnisec.png'

export default [
  {
    id: 'omnisec_app',
    title: 'OmniSec',
    path: 'https://omnisec.app',
    description:
      'Everything Security - One stop for all the security updates - A React App - Live on Playstore',
    staticThumbnail: omnisec,
  },
  {
    id: 'note_pass_keeper',
    title: 'NotePass',
    path: 'https://github.com/LuD1161/Flutter_Pass_Notes',
    description: 'A free, open source password keeper made using flutter.',
    staticThumbnail: flutter_pass_notes,
  },
  {
    id: 'android_crashy_ball',
    title: 'CrashyBall',
    path: 'https://github.com/LuD1161/AndroidGame-CrashyBall',
    description: 'An android game developed in JAVA during my college.',
    staticThumbnail: crashy_ball,
  },
  {
    id: 'python_rat',
    title: 'Python RAT',
    path: 'https://github.com/LuD1161/MyPythonAdministrationTool',
    description:
      'A python RAT. Keylogger, Browser Password Stealing, Screenshots and RCE on the victim machine. Spreads itself through copying on USB drives.',
    staticThumbnail: python_rat,
  },
  {
    id: 'django_social_network',
    title: 'Social Network - django',
    path: 'https://github.com/LuD1161/django-Website',
    description: 'A social network created using django framework.',
    staticThumbnail: django_social_network,
  },
]
